import styled, { css } from "styled-components"

export const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  min-height: calc(100vh - 120px);

  @media (max-width: 800px) {
    padding: 1rem;
  }

  .onda {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40rem;
    z-index: -20;
  }

  .bolanaranja {
    position: absolute;
    left: -20px;
    width: 40%;
    z-index: -20;
    top: 15rem;
    transform: scaleX(-1);
    opacity: 0.5;
    @media (max-width: 890px) {
      display: none;
    }
  }
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  @media (max-width: 800px) {
    padding: 1rem;
  }

  /* background-color: red; */
  height: 100%;
  /* margin-top: 1rem; */

  ${props =>
    props.column &&
    css`
      flex-direction: column;
    `}

  svg {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 50%;
    @media (max-width: 800px) {
      width: 90%;
    }
  }
`

export const TargetaPrecio = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 1.2rem;
  border-radius: 15px;
  background-color: white;
  height: 15rem;
  width: 15rem;
  color: #2c3d63;
  margin: 0.5rem;
  -webkit-box-shadow: 0px 10px 28px -11px rgba(173, 220, 202, 1);
  -moz-box-shadow: 0px 10px 28px -11px rgba(173, 220, 202, 1);
  box-shadow: 0px 10px 28px -11px rgba(173, 220, 202, 1);

  p.alumno {
    font-family: "pmedium";
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0px;
  }

  div.junto {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p.precio {
      font-family: "pbold";
      font-size: 42px;
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 0px;
    }
    p.horas {
      font-family: "plight";
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 0px;
    }
    p.maximo {
      font-family: "plight";
      text-align: center;
      font-size: 11px;
      display: flex;
      justify-content: center;
      margin: 0px;
      color: darkgray;
      margin-top: 5px;
    }
  }

  p.matricula {
    font-family: "plight";
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0px;
    color: darkgray;
  }
`

export const Divider = styled.div`
  color: #2c3d63;
  border-bottom: 2px solid #2c3d63;
  background-color: transparent;
  font-size: 25px;
  font-family: "psemibold";
  display: flex;
  justify-content: center;
  padding: 0.6rem;
  margin: 0.3rem;
  align-items: center;
  text-align: center;
`

export const InfoSnack = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  p.title {
    color: #2c3d63;
    font-family: "pbold";
    font-size: 23px;
    margin: 0.3rem;
    text-align: center;
  }
  p.information {
    color: #2c3d63;
    text-align: center;
    margin: 0px;
    font-family: "plight";
  }
`
