import React from "react"
import Layout from "../components/Layout"
import { Container } from "../styles/ContactoStyle"

import Onda from "../assets/icons/ondasup.svg"
import BolaVerde from "../assets/icons/bolaverde.svg"
import BolaNaranja from "../assets/icons/bolanaranja.svg"
import { Title } from "../styles/Common"
import { InfoContainer, InfoSnack } from "../styles/CursosStyle"

const Contacto = () => {
  return (
    <Layout>
      <Container>
        <Onda className="onda" />
        <BolaVerde className="bolaverde" />
        <Title>
          <h2>¿Necesitas contactar con nosotros?</h2>
        </Title>
        <div className="mapaContainer">
          <div className="mapa">
            <div style={{ width: "25em", height: "25em" }}>
              <iframe
                width="100%"
                height="600"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Calle%20Cervantes%2019%20Villena+(School%20of%20London%20English)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
          <InfoContainer style={{ flexDirection: "column" }}>
            <InfoSnack>
              <p className="title">Dirección</p>
              <p className="information">C/ Cervantes 19, Villena (Alicante)</p>
            </InfoSnack>
            <InfoSnack>
              <p className="title">Teléfono</p>
              <p className="information">639-662-840</p>
            </InfoSnack>
            <InfoSnack>
              <p className="title">Atención al cliente</p>
              <p className="information">de 17:00 a 20:00</p>
            </InfoSnack>
            <InfoSnack>
              <p className="title">Correo electrónico</p>
              <p className="information">janinedavis433@gmail.com</p>
            </InfoSnack>
          </InfoContainer>
        </div>
      </Container>
    </Layout>
  )
}

export default Contacto
