import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  /* background-color: pink; */
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  @media (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    height: fit-content;
  }

  .onda {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40rem;
    z-index: -20;
  }
  .bolaverde {
    position: absolute;
    left: -2.9rem;
    top: 13rem;
    width: 50%;
    z-index: -20;
    @media (max-width: 890px) {
      display: none;
    }
  }

  .bolanaranja {
    position: absolute;
    right: -20px;
    width: 50%;
    z-index: -20;
    top: 10rem;
    @media (max-width: 890px) {
      display: none;
    }
  }

  div.mapaContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    align-items: center;
    /* flex-wrap: wrap; */
    @media (max-width: 890px) {
      /* display: flex; */
      flex-direction: column;
      /* height: 100%; */
      width: 100%;
    }

    div.mapa {
      display: flex;
      justify-content: center;
      height: fit-content;
      /* width: fit-content; */
      /* background-color: blue; */
      border-radius: 15px;
      overflow: hidden;
      /* width: 30px; */
    }
    div.mapaIframe {
      width: 30em;
      height: 30em;
    }
    div.info {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 50%;
      /* background-color: red; */
      padding: 3rem;
    }
  }
`
